import { Contracts } from '../types';
import * as avalanche from '@/assets/data/contracts/avalanche.json';

const contracts: Contracts = {
  distributionAssessor: '0x0000000000000000000000000000000000000000',
  securitizationPoolValueService: '0x0000000000000000000000000000000000000000',
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: '',
  multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  authorizer: avalanche.Authorizer,
  vault: avalanche.Vault,
  weightedPoolFactory: avalanche.WeightedPoolFactory,
  stablePoolFactory: avalanche.ComposableStablePoolFactory,
  lidoRelayer: '',
  balancerHelpers: avalanche.BalancerHelpers,
  batchRelayer: avalanche.BalancerRelayer,
  gaugeFactory: avalanche.ChildChainLiquidityGaugeFactory,
  balancerMinter: avalanche.L2BalancerPseudoMinter,
  acceptedInvoiceToken: '0x0000000000000000000000000000000000000000',
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '',
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  gaugeRewardsHelper: avalanche.ChildChainGaugeRewardHelper,
  untangled: {
    securitizationManagementProject:
      '0x3828A20e026d4332CdEb8aDa9C2D21502d71885a',
    securitizationPoolValueService:
      '0xC43a2664F874fa482D31B70BC997B73353636084',
    noteTokenVault: '0x1576C2925360C03d37341e6D9d60e6AE644Fe84a',
    distributionAssessor: '', // TODO Should be removed
    distributionTranche: '', // TODO should be removed
    distributionOperator: '', // TODO safe to be removed
    uniqueIdentity: '0xF2Ba1144481089888ec22db80972A099B2Af6668',
    loanAssetToken: '0xF5B64EadC3469Ff56c3244272504A9AB8b4cF4b2',
    vault: '0x000000000000000000000000000000000000000000',
  },
};

export default contracts;
