import { Contracts } from '../types';
import * as polygon from '@/assets/data/contracts/polygon.json';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '0x0F3e0c4218b7b0108a3643cFe9D3ec0d4F57c54e',
  merkleOrchardV2: polygon.MerkleOrchard,
  multicall: '0x275617327c958bD06b5D6b871E7f491D76113dd8',
  authorizer: polygon.Authorizer,
  vault: polygon.Vault,
  weightedPoolFactory: polygon.WeightedPoolFactory,
  stablePoolFactory: '0xc66Ba2B6595D3613CCab350C886aCE23866EDe24',

  lidoRelayer: '',
  balancerHelpers: '0x94905e703fead7f0fd0eee355d267ee909784e6d',
  batchRelayer: polygon.BalancerRelayer,
  gaugeFactory: polygon.ChildChainLiquidityGaugeFactory,
  balancerMinter: polygon.L2BalancerPseudoMinter,
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '0x0f08eEf2C785AA5e7539684aF04755dEC1347b7c',
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  gaugeRewardsHelper: polygon.ChildChainGaugeRewardHelper,
  gaugeWorkingBalanceHelper: polygon.ChildChainGaugeWorkingBalanceHelper,

  distributionAssessor: '0x50De8D7eC5DBF277DE40689CbFEE1F134Aa3EfEf',
  securitizationPoolValueService: '0x8B051d2C12225B5b9D3d1138e7d77086894e61C0',
  acceptedInvoiceToken: '',
  distributionOperator: '',
  untangled: {
    securitizationManagementProject:
      '0x9fEd89D310ca236906e2043C8eB92E4aae71c769',
    securitizationPoolValueService:
      '0x8B051d2C12225B5b9D3d1138e7d77086894e61C0',
    noteTokenVault: '0xDe4CE95eE32884243247569A136e7B42bc654717',
    distributionAssessor: '0x50De8D7eC5DBF277DE40689CbFEE1F134Aa3EfEf',
    distributionTranche: '',
    distributionOperator: '',
    uniqueIdentity: '0x89C280ede54c52a5D7BA3075034A0adb927FAcbf',
    loanAssetToken: '0x625516cD3E1144e1c80725Dd0758c246eBc2DC98',
    vault: '0x0000000000000000000000000000000000000000',
  },
};

export default contracts;
