import { TokenInfo } from '@/types/TokenList';
import { Network } from '.';

/**
 * FIXME: Re-check again the app logic and try to make these list dynamically instead of hard-coded like this
 */
const ethereum: TokenInfo[] = [
  {
    chainId: 1,
    symbol: 'USDC',
    name: 'USD Coin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
    decimals: 6,
    address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  },
  {
    chainId: 1,
    symbol: 'USDT',
    name: 'Tether USD',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    decimals: 6,
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  },
  {
    chainId: 1,
    symbol: 'DAI',
    name: 'DAI Stablecoin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png',
    decimals: 18,
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  },
];

const polygonPoS: TokenInfo[] = [
  {
    chainId: 137,
    symbol: 'USDC',
    name: 'USD Coin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
    decimals: 6,
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
  },
  {
    chainId: 137,
    symbol: 'fakeUSDC',
    name: 'fake USD Coin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
    decimals: 6,
    address: '0x3AD4E117eE7c074D989E33dbE3d92450E373a820',
  },
  {
    chainId: 137,
    symbol: 'USDT',
    name: 'Tether USD',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    decimals: 6,
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  },
];

const celo: TokenInfo[] = [
  {
    chainId: 42220,
    symbol: 'USDC',
    name: 'USD Coin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
    decimals: 6,
    address: '0xceba9300f2b948710d2653dd7b07f33a8b32118c',
  },
];

const alfajores: TokenInfo[] = [
  {
    chainId: 44787,
    symbol: 'USDC',
    name: 'USD Coin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
    decimals: 6,
    address: '0xe3398bab66b00f2e4ae551968b9a91d064186b66',
  },
];

const mumbai: TokenInfo[] = [
  {
    chainId: Network.MUMBAI,
    symbol: 'USDC',
    name: 'USD Coin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    decimals: 6,
    address: '0xF86Bfa8f04144cb2179bc09E495e52F061A8328A',
  },
];

const amoy: TokenInfo[] = [
  {
    chainId: Network.AMOY,
    symbol: 'USDC',
    name: 'USD Official Coin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    decimals: 6,
    address: '0x41E94Eb019C0762f9Bfcf9Fb1E58725BfB0e7582',
  },
  {
    chainId: Network.AMOY,
    symbol: 'USDCU',
    name: 'USD Coin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    decimals: 6,
    address: '0x6f48Ef99a294d5C9F394A0a08f4149b1f350441a',
  },
];

const sepolia: TokenInfo[] = [
  {
    chainId: Network.SEPOLIA,
    symbol: 'USDC',
    name: 'USD Official Coin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    decimals: 6,
    address: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
  },
];

const baseSepolia: TokenInfo[] = [
  {
    chainId: Network.BASE_SEPOLIA,
    symbol: 'USDC',
    name: 'USD Official Coin',
    logoURI: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    decimals: 6,
    address: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
  },
];
export const tokensByNetworkId: Record<number, TokenInfo[]> = {
  [Network.MAINNET]: ethereum,
  [Network.POLYGON]: polygonPoS,
  [Network.CELO]: celo,
  [Network.ALFAJORES]: alfajores,
  [Network.MUMBAI]: mumbai,
  [Network.AMOY]: amoy,
  [Network.SEPOLIA]: sepolia,
  [Network.BASE_SEPOLIA]: baseSepolia,
};
