import { Contracts } from '../types';
import * as optimism from '@/assets/data/contracts/optimism.json';

const contracts: Contracts = {
  distributionAssessor: '0x0000000000000000000000000000000000000000',
  securitizationPoolValueService: '0x0000000000000000000000000000000000000000',
  merkleRedeem: '',
  merkleOrchard: '',
  multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  authorizer: '',
  vault: '',
  weightedPoolFactory: optimism.WeightedPoolFactory,
  stablePoolFactory: '',
  lidoRelayer: '',
  balancerHelpers: '',
  batchRelayer: optimism.BalancerRelayer,

  acceptedInvoiceToken: '0x0000000000000000000000000000000000000000',
  gaugeFactory: '',
  balancerMinter: optimism.L2BalancerPseudoMinter,
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '0x9dA18982a33FD0c7051B19F0d7C76F2d5E7e017c',
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  gaugeWorkingBalanceHelper: optimism.ChildChainGaugeWorkingBalanceHelper,

  untangled: {
    securitizationManagementProject:
      '0xe3f840Fa081f7b1c81a5F5958bab1786FE6d7c36',
    securitizationPoolValueService:
      '0xFDe49A3d9133c94cBbab5167AEB469D7fE797bf2',
    noteTokenVault: '0x4273EB78c06CbdE296CD74F952A423098b7AbE61',
    distributionAssessor: '', // TODO Should be removed
    distributionTranche: '', // TODO should be removed
    distributionOperator: '', // TODO safe to be removed
    uniqueIdentity: '0x8fbbE267977850e2b5D2BB9528C5D53241b61Bb8',
    loanAssetToken: '0xB00fD73fd075b88467a47C9937408DBA143f6649',
    vault: '0xAA1dD21066383b1aC4652Ea41C7520Fb373840Bc',
  },
};

export default contracts;
