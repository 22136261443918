import { Config } from '../types';
import contracts from './contracts';
import pools from './pools';
import tokenlists from './tokenlists';
import tokens from './tokens';

const config: Config = {
  key: '11155111',
  chainId: 11155111,
  layerZeroChainId: 111,
  supportsVeBalSync: true,
  chainName: 'Sepolia',
  name: 'Sepolia Testnet',
  shortName: 'Sepolia',
  monorepoName: 'sepolia',
  slug: 'sepolia',
  network: 'sepolia',
  trustWalletNetwork: 'sepolia',
  unknown: false,
  visibleInUI: true,
  testNetwork: true,
  rpc: 'https://rpc.sepolia.org',
  ws: 'wss://sepolia.drpc.org',
  publicRpc: 'https://rpc.sepolia.org',
  blockTime: 13,
  explorer: 'https://sepolia.etherscan.io/',
  explorerName: 'Sepolia Testnet Explorer',
  subgraph:
    'https://api.thegraph.com/subgraphs/name/beethovenxfi/beethovenx-v2-optimism',
  poolsUrlV2: '',
  subgraphs: {
    main: [
      'https://api.thegraph.com/subgraphs/name/beethovenxfi/beethovenx-v2-optimism',
    ],
    aave: '',
    gauge:
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-gauges-optimism',
    blocks: '',
  },
  bridgeUrl: '',
  supportsEIP1559: false,
  supportsElementPools: false,
  nativeAsset: {
    name: 'Ether',
    address: '0x2e5221B0f855Be4ea5Cefffb8311EED0563B6e87',
    symbol: 'SETH',
    decimals: 18,
    deeplinkId: 'ether',
    logoURI: 'tokens/eth.png',
    minTransactionBuffer: '0.05',
  },
  vaultToken: {
    name: 'Vault',
    address: '0xAA1dD21066383b1aC4652Ea41C7520Fb373840Bc',
    managerAddress: '0x0000000000000000000000000000000000000000',
    symbol: 'VAULT',
    decimals: 12,
    assetDecimals: 6,
    assetSymbol: 'USDC',
    assetAddress: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238',
    logoURI: 'tokens/celo.png',
    poolId: 496,
  },

  thirdParty: {
    coingecko: {
      nativeAssetId: 'ethereum',
      platformId: 'sepolia',
    },
  },
  addresses: {
    ...contracts,
  },
  keys: {
    infura: '',
    alchemy: '',
  },
  gauges: {
    type: 5,
    weight: 0,
  },
  pools,
  tokenlists,
  tokens,
  rateProviders: {},
};

export default config;
